/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useState, useEffect } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const BenefitsROISimulator = () => {
  // State for company details
  const [companyDetails, setCompanyDetails] = useState({
    employeeCount: "",
    averageSalary: "",
    industry: "technology",
    turnoverRate: "",
  })

  // State for benefit packages
  const [benefitPackages, setBenefitPackages] = useState({
    current: {
      healthInsurance: { included: true, costPerEmployee: 6000 },
      dentalVision: { included: true, costPerEmployee: 1000 },
      retirement401k: { included: true, matchPercent: 3 },
      paidTimeOff: { included: true, daysPerYear: 15 },
      lifeInsurance: { included: false, costPerEmployee: 200 },
      wellnessProgram: { included: false, costPerEmployee: 300 },
      profDevBudget: { included: false, costPerEmployee: 1000 },
      parentalLeave: { included: false, weeksPerYear: 0 },
    },
    proposed: {
      healthInsurance: { included: true, costPerEmployee: 7200 },
      dentalVision: { included: true, costPerEmployee: 1200 },
      retirement401k: { included: true, matchPercent: 4 },
      paidTimeOff: { included: true, daysPerYear: 20 },
      lifeInsurance: { included: true, costPerEmployee: 200 },
      wellnessProgram: { included: true, costPerEmployee: 300 },
      profDevBudget: { included: true, costPerEmployee: 1000 },
      parentalLeave: { included: true, weeksPerYear: 12 },
    },
  })

  // Industry options
  const industries = {
    technology: "Technology",
    healthcare: "Healthcare",
    finance: "Finance",
    retail: "Retail",
    manufacturing: "Manufacturing",
    consulting: "Consulting",
  }

  // Impact factors based on industry research
  const impactFactors = {
    technology: {
      turnoverReduction: 0.22,
      productivityIncrease: 0.12,
      satisfactionIncrease: 0.25,
      costPerHire: 1.5, // as multiple of salary
      trainingCost: 5000, // per employee
    },
    healthcare: {
      turnoverReduction: 0.18,
      productivityIncrease: 0.10,
      satisfactionIncrease: 0.20,
      costPerHire: 1.2,
      trainingCost: 4000,
    },
    finance: {
      turnoverReduction: 0.20,
      productivityIncrease: 0.11,
      satisfactionIncrease: 0.23,
      costPerHire: 1.4,
      trainingCost: 6000,
    },
    retail: {
      turnoverReduction: 0.15,
      productivityIncrease: 0.08,
      satisfactionIncrease: 0.18,
      costPerHire: 0.8,
      trainingCost: 2000,
    },
    manufacturing: {
      turnoverReduction: 0.12,
      productivityIncrease: 0.07,
      satisfactionIncrease: 0.15,
      costPerHire: 1.0,
      trainingCost: 3000,
    },
    consulting: {
      turnoverReduction: 0.20,
      productivityIncrease: 0.13,
      satisfactionIncrease: 0.24,
      costPerHire: 1.3,
      trainingCost: 5500,
    },
  }

  // Input validation
  const validateInputs = (details) => {
    const errors = {}
    if (!details.employeeCount || details.employeeCount <= 0) {
      errors.employeeCount = "Please enter a valid number of employees"
    }
    if (!details.averageSalary || details.averageSalary <= 0) {
      errors.averageSalary = "Please enter a valid average salary"
    }
    if (!details.turnoverRate || details.turnoverRate < 0 || details.turnoverRate > 100) {
      errors.turnoverRate = "Please enter a valid turnover rate (0-100)"
    }
    return errors
  }

  // Calculate total costs and ROI
  const calculateROI = () => {
    const employeeCount = Number.parseInt(companyDetails.employeeCount) || 0
    const averageSalary = Number.parseInt(companyDetails.averageSalary) || 0
    const turnoverRate = Number.parseFloat(companyDetails.turnoverRate) || 0
    
    // Validate inputs
    const errors = validateInputs(companyDetails)
    if (Object.keys(errors).length > 0) {
      return {
        errors,
        currentAnnualCost: 0,
        proposedAnnualCost: 0,
        additionalCost: 0,
        turnoverSavings: 0,
        productivityGain: 0,
        totalBenefit: 0,
        roi: 0,
      }
    }

    // Calculate current package costs
    const currentAnnualCost = Object.entries(benefitPackages.current).reduce((total, [benefit, details]) => {
      if (!details.included) return total
      if (benefit === 'retirement401k') {
        return total + (averageSalary * (details.matchPercent / 100) * employeeCount)
      }
      return total + (details.costPerEmployee * employeeCount)
    }, 0)

    // Calculate proposed package costs
    const proposedAnnualCost = Object.entries(benefitPackages.proposed).reduce((total, [benefit, details]) => {
      if (!details.included) return total
      if (benefit === 'retirement401k') {
        return total + (averageSalary * (details.matchPercent / 100) * employeeCount)
      }
      return total + (details.costPerEmployee * employeeCount)
    }, 0)

    // Calculate impact based on industry factors
    const factors = impactFactors[companyDetails.industry]
    
    // Calculate turnover costs more comprehensively
    const employeesLeavingAnnually = Math.round(employeeCount * (turnoverRate / 100))
    const currentTurnoverCost = employeesLeavingAnnually * (
      averageSalary * factors.costPerHire + // Recruitment and replacement costs
      factors.trainingCost + // Training costs
      (averageSalary * 0.5) // Lost productivity during transition (50% of salary)
    )
    
    const proposedTurnoverCost = currentTurnoverCost * (1 - factors.turnoverReduction)
    const turnoverSavings = currentTurnoverCost - proposedTurnoverCost

    // Calculate productivity gains more accurately
    const baseProductivity = employeeCount * averageSalary
    const productivityGain = baseProductivity * factors.productivityIncrease * 
      (Object.keys(benefitPackages.proposed).filter(k => benefitPackages.proposed[k].included).length / 
       Object.keys(benefitPackages.proposed).length) // Scale by proportion of benefits offered

    // Calculate ROI
    const additionalCost = proposedAnnualCost - currentAnnualCost
    const totalBenefit = turnoverSavings + productivityGain

    // Handle edge case where additional cost is 0
    const roi = additionalCost === 0 
      ? (totalBenefit > 0 ? 100 : 0) 
      : ((totalBenefit - additionalCost) / Math.abs(additionalCost) * 100)

    return {
      errors: {},
      currentAnnualCost: Math.round(currentAnnualCost),
      proposedAnnualCost: Math.round(proposedAnnualCost),
      additionalCost: Math.round(additionalCost),
      turnoverSavings: Math.round(turnoverSavings),
      productivityGain: Math.round(productivityGain),
      totalBenefit: Math.round(totalBenefit),
      roi: Math.round(roi),
      details: {
        employeesLeavingAnnually,
        currentTurnoverCost: Math.round(currentTurnoverCost),
        proposedTurnoverCost: Math.round(proposedTurnoverCost),
        baseProductivity: Math.round(baseProductivity),
      }
    }
  }

  const handleCompanyDetailsChange = (field, value) => {
    setCompanyDetails(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const toggleBenefit = (package_type, benefit) => {
    setBenefitPackages(prev => ({
      ...prev,
      [package_type]: {
        ...prev[package_type],
        [benefit]: {
          ...prev[package_type][benefit],
          included: !prev[package_type][benefit].included
        }
      }
    }))
  }

  const updateBenefitValue = (package_type, benefit, field, value) => {
    setBenefitPackages(prev => ({
      ...prev,
      [package_type]: {
        ...prev[package_type],
        [benefit]: {
          ...prev[package_type][benefit],
          [field]: value
        }
      }
    }))
  }

  const results = calculateROI()

  return (
    <Layout className="benefits-roi-simulator-page">
      <SEO 
        title="Benefits ROI Simulator | Compare Employee Benefits Packages" 
        description="Calculate and compare the ROI of different employee benefits packages. Make data-driven decisions about your benefits offerings."
      />
      <div className="wrapper" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <h1 sx={{ fontSize: ['1.75rem', '2rem', '2.25rem'], mb: 4 }}>
          Benefits ROI Simulator
        </h1>

        {/* Description Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.3rem', mb: 3, color: 'primary' }}>About This Tool</h2>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
            Compare different benefits packages and calculate their potential return on investment. 
            This simulator helps you make data-driven decisions about your benefits offerings by considering:
          </p>
          <ul sx={{ 
            listStyle: 'disc',
            pl: 4,
            mb: 3,
            '& li': { 
              mb: 2,
              fontSize: '1rem',
              lineHeight: '1.5'
            }
          }}>
            <li>Direct costs of benefits</li>
            <li>Impact on employee turnover</li>
            <li>Productivity improvements</li>
            <li>Employee satisfaction metrics</li>
          </ul>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', fontStyle: 'italic' }}>
            Calculations based on industry research and real-world case studies.
          </p>
        </div>

        {/* Company Details Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Company Details</h2>
          <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4 }}>
            <div>
              <label htmlFor="employeeCount" sx={{ display: 'block', mb: 2 }}>
                Number of Employees
                <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                  Total full-time employees
                </span>
              </label>
              <input
                type="number"
                id="employeeCount"
                value={companyDetails.employeeCount}
                onChange={(e) => handleCompanyDetailsChange('employeeCount', e.target.value)}
                placeholder="e.g., 100"
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: results.errors.employeeCount ? 'red' : 'gray.3',
                  borderRadius: '4px'
                }}
              />
              {results.errors.employeeCount && (
                <span sx={{ color: 'red', fontSize: '0.875rem', mt: 1, display: 'block' }}>
                  {results.errors.employeeCount}
                </span>
              )}
            </div>

            <div>
              <label htmlFor="averageSalary" sx={{ display: 'block', mb: 2 }}>
                Average Annual Salary (USD)
                <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                  Average salary per employee
                </span>
              </label>
              <input
                type="number"
                id="averageSalary"
                value={companyDetails.averageSalary}
                onChange={(e) => handleCompanyDetailsChange('averageSalary', e.target.value)}
                placeholder="e.g., 50000"
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: results.errors.averageSalary ? 'red' : 'gray.3',
                  borderRadius: '4px'
                }}
              />
              {results.errors.averageSalary && (
                <span sx={{ color: 'red', fontSize: '0.875rem', mt: 1, display: 'block' }}>
                  {results.errors.averageSalary}
                </span>
              )}
            </div>

            <div>
              <label htmlFor="industry" sx={{ display: 'block', mb: 2 }}>
                Industry
                <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                  Select your primary industry
                </span>
              </label>
              <select
                id="industry"
                value={companyDetails.industry}
                onChange={(e) => handleCompanyDetailsChange('industry', e.target.value)}
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'gray.3',
                  borderRadius: '4px'
                }}
              >
                {Object.entries(industries).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="turnoverRate" sx={{ display: 'block', mb: 2 }}>
                Current Annual Turnover Rate (%)
                <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                  Percentage of employees who leave annually
                </span>
              </label>
              <input
                type="number"
                id="turnoverRate"
                value={companyDetails.turnoverRate}
                onChange={(e) => handleCompanyDetailsChange('turnoverRate', e.target.value)}
                placeholder="e.g., 15"
                sx={{
                  width: '100%',
                  p: 2,
                  border: '1px solid',
                  borderColor: results.errors.turnoverRate ? 'red' : 'gray.3',
                  borderRadius: '4px'
                }}
              />
              {results.errors.turnoverRate && (
                <span sx={{ color: 'red', fontSize: '0.875rem', mt: 1, display: 'block' }}>
                  {results.errors.turnoverRate}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Benefits Packages Comparison */}
        <div sx={{ 
          display: 'grid', 
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'], 
          gap: 4,
          mb: 4
        }}>
          {/* Current Package */}
          <div sx={{ 
            bg: 'white', 
            p: 4, 
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
          }}>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Current Package</h2>
            <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {Object.entries(benefitPackages.current).map(([benefit, details]) => (
                <div key={benefit}>
                  <label sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}>
                    <input
                      type="checkbox"
                      checked={details.included}
                      onChange={() => toggleBenefit('current', benefit)}
                      sx={{ mr: 2 }}
                    />
                    <div sx={{ flex: 1 }}>
                      <span sx={{ display: 'block' }}>
                        {benefit.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                      </span>
                      {details.included && (
                        <div sx={{ mt: 2 }}>
                          {benefit === 'retirement401k' ? (
                            <input
                              type="number"
                              value={details.matchPercent}
                              onChange={(e) => updateBenefitValue('current', benefit, 'matchPercent', Number.parseInt(e.target.value))}
                              placeholder="Match %"
                              sx={{
                                width: '100%',
                                p: 2,
                                border: '1px solid',
                                borderColor: 'gray.3',
                                borderRadius: '4px'
                              }}
                            />
                          ) : benefit === 'paidTimeOff' || benefit === 'parentalLeave' ? (
                            <input
                              type="number"
                              value={details[benefit === 'paidTimeOff' ? 'daysPerYear' : 'weeksPerYear']}
                              onChange={(e) => updateBenefitValue('current', benefit, 
                                benefit === 'paidTimeOff' ? 'daysPerYear' : 'weeksPerYear', 
                                Number.parseInt(e.target.value)
                              )}
                              placeholder={benefit === 'paidTimeOff' ? "Days per year" : "Weeks per year"}
                              sx={{
                                width: '100%',
                                p: 2,
                                border: '1px solid',
                                borderColor: 'gray.3',
                                borderRadius: '4px'
                              }}
                            />
                          ) : (
                            <input
                              type="number"
                              value={details.costPerEmployee}
                              onChange={(e) => updateBenefitValue('current', benefit, 'costPerEmployee', Number.parseInt(e.target.value))}
                              placeholder="Cost per employee"
                              sx={{
                                width: '100%',
                                p: 2,
                                border: '1px solid',
                                borderColor: 'gray.3',
                                borderRadius: '4px'
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Proposed Package */}
          <div sx={{ 
            bg: 'white', 
            p: 4, 
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
          }}>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Proposed Package</h2>
            <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {Object.entries(benefitPackages.proposed).map(([benefit, details]) => (
                <div key={benefit}>
                  <label sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}>
                    <input
                      type="checkbox"
                      checked={details.included}
                      onChange={() => toggleBenefit('proposed', benefit)}
                      sx={{ mr: 2 }}
                    />
                    <div sx={{ flex: 1 }}>
                      <span sx={{ display: 'block' }}>
                        {benefit.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                      </span>
                      {details.included && (
                        <div sx={{ mt: 2 }}>
                          {benefit === 'retirement401k' ? (
                            <input
                              type="number"
                              value={details.matchPercent}
                              onChange={(e) => updateBenefitValue('proposed', benefit, 'matchPercent', Number.parseInt(e.target.value))}
                              placeholder="Match %"
                              sx={{
                                width: '100%',
                                p: 2,
                                border: '1px solid',
                                borderColor: 'gray.3',
                                borderRadius: '4px'
                              }}
                            />
                          ) : benefit === 'paidTimeOff' || benefit === 'parentalLeave' ? (
                            <input
                              type="number"
                              value={details[benefit === 'paidTimeOff' ? 'daysPerYear' : 'weeksPerYear']}
                              onChange={(e) => updateBenefitValue('proposed', benefit, 
                                benefit === 'paidTimeOff' ? 'daysPerYear' : 'weeksPerYear', 
                                Number.parseInt(e.target.value)
                              )}
                              placeholder={benefit === 'paidTimeOff' ? "Days per year" : "Weeks per year"}
                              sx={{
                                width: '100%',
                                p: 2,
                                border: '1px solid',
                                borderColor: 'gray.3',
                                borderRadius: '4px'
                              }}
                            />
                          ) : (
                            <input
                              type="number"
                              value={details.costPerEmployee}
                              onChange={(e) => updateBenefitValue('proposed', benefit, 'costPerEmployee', Number.parseInt(e.target.value))}
                              placeholder="Cost per employee"
                              sx={{
                                width: '100%',
                                p: 2,
                                border: '1px solid',
                                borderColor: 'gray.3',
                                borderRadius: '4px'
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Results Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>ROI Analysis</h2>
          <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'], gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Current Annual Cost</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                ${results.currentAnnualCost.toLocaleString()}
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Proposed Annual Cost</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                ${results.proposedAnnualCost.toLocaleString()}
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Additional Investment</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: results.additionalCost > 0 ? 'red' : 'green' }}>
                ${results.additionalCost.toLocaleString()}
              </p>
            </div>
          </div>

          <div sx={{ mt: 4, display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'], gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Turnover Savings</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'green' }}>
                ${results.turnoverSavings.toLocaleString()}
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Productivity Gain</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'green' }}>
                ${results.productivityGain.toLocaleString()}
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Expected ROI</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: results.roi > 0 ? 'green' : 'red' }}>
                {results.roi}%
              </p>
            </div>
          </div>
        </div>

        {/* Add Detailed Analysis Section */}
        {!Object.keys(results.errors).length && results.details && (
          <div sx={{ 
            bg: 'white', 
            p: 4, 
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            mb: 4
          }}>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Detailed Analysis</h2>
            <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4 }}>
              <div>
                <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Annual Employee Turnover</h3>
                <p sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                  {results.details.employeesLeavingAnnually} employees/year
                </p>
              </div>
              <div>
                <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Current Turnover Cost</h3>
                <p sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                  ${results.details.currentTurnoverCost.toLocaleString()}
                </p>
              </div>
              <div>
                <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Projected Turnover Cost</h3>
                <p sx={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'green' }}>
                  ${results.details.proposedTurnoverCost.toLocaleString()}
                </p>
              </div>
              <div>
                <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Base Productivity Value</h3>
                <p sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                  ${results.details.baseProductivity.toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Recommendations Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Recommendations</h2>
          <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {results.roi > 0 ? (
              <>
                <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                  The proposed benefits package shows a positive ROI of {results.roi}%. Key advantages include:
                </p>
                <ul sx={{ 
                  listStyle: 'disc',
                  pl: 4,
                  '& li': { 
                    mb: 2,
                    fontSize: '1rem',
                    lineHeight: '1.5'
                  }
                }}>
                  <li>Reduced turnover costs: ${results.turnoverSavings.toLocaleString()} annual savings</li>
                  <li>Increased productivity value: ${results.productivityGain.toLocaleString()} annual gain</li>
                  <li>Total benefit: ${results.totalBenefit.toLocaleString()} per year</li>
                </ul>
                <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                  Consider implementing the proposed package to improve employee satisfaction and retention while 
                  generating positive returns on your investment.
                </p>
              </>
            ) : (
              <>
                <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                  The proposed benefits package shows a negative ROI of {results.roi}%. Consider adjusting the package:
                </p>
                <ul sx={{ 
                  listStyle: 'disc',
                  pl: 4,
                  '& li': { 
                    mb: 2,
                    fontSize: '1rem',
                    lineHeight: '1.5'
                  }
                }}>
                  <li>Review the cost structure of high-expense benefits</li>
                  <li>Consider phasing in benefits over time</li>
                  <li>Focus on benefits with the highest impact on retention and productivity</li>
                </ul>
                <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                  Adjust the proposed package to find a better balance between cost and impact.
                </p>
              </>
            )}
          </div>
        </div>

        {/* FAQ Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 4, color: 'primary' }}>Frequently Asked Questions</h2>
          
          <div sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                How is ROI calculated?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                ROI is calculated by comparing the additional investment in benefits against the expected returns 
                from reduced turnover and increased productivity. We use industry-specific data to estimate these 
                impacts based on research and case studies.
              </p>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                What factors influence benefit effectiveness?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                The effectiveness of benefits packages varies by industry, company size, and workforce demographics. 
                Key factors include employee preferences, market competitiveness, and implementation quality. 
                Consider surveying your employees to understand their priorities.
              </p>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                How accurate are the projections?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                Projections are based on industry averages and research data. Actual results may vary based on 
                your specific circumstances, implementation effectiveness, and market conditions. Use these 
                estimates as directional guidance rather than exact predictions.
              </p>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                How often should benefits be reviewed?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                We recommend reviewing your benefits package annually to ensure it remains competitive and 
                effective. Consider market trends, employee feedback, and utilization rates when making 
                adjustments. Regular reviews help maintain the package's value proposition.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BenefitsROISimulator 